import React from "react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const Home: React.FC = () => {
  return (
    <div className="home">
      <div className="home__header">
        Welcome Y Combinator ... to the future of education!
      </div>
      <div className="home__cm-wrapper">
        <div className="home__logo home__logo-nfe">
          <span className="home__logo--upper">NO FUSS</span>
          <span className="home__logo--lower-education">EDUCATION</span>
        </div>
        <div className="home__cm home__cm--1">
          <img
            src="https://nft-image-pool.s3.us-east-2.amazonaws.com/no-fuss-education/cm1.png"
            alt="Reviews"
            className="home__cm--img"
          />
        </div>
        <div className="home__cm home__cm--2">
          <img
            src="https://nft-image-pool.s3.us-east-2.amazonaws.com/no-fuss-education/cm2.png"
            alt="Reviews"
            className="home__cm--img"
          />
        </div>
      </div>
      <div className="home__one-liner">
        Building a Video Game to Trick Kids into Learning
      </div>
      <div className="home__products-wrapper">
        <div className="home__card home__nft">
          <div className="home__card--label">Tutoring (Upsell)</div>
          <div className="home__logo home__logo-nft">
            <span className="home__logo--upper">NO FUSS</span>
            <span className="home__logo--lower">TUTORS</span>
          </div>

          <div className="home__card--middle-nft">
            <img
              src="https://nft-image-pool.s3.us-east-2.amazonaws.com/no-fuss-education/Reviews.png"
              alt="Reviews"
              className="home__reviews"
            />
          </div>
          <div className="home__list">
            <div className="home__list--row">
              <div className="home__list--icon">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div>$10k USD Net MRR</div>
            </div>
            <div className="home__list--row">
              <div className="home__list--icon">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div>
                {" "}
                <a
                  className="home__link-nft"
                  href="https://nofusstutors.com/"
                  target="_blank"
                >
                  www.nofusstutors.com
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="home__card home__project-moonrock">
          <div className="home__card--label home__card--label-game">
            Video Game (Focus)
          </div>
          <div className="home__logo home__logo-pmr">
            <div>
              <img
                src="https://moonrock-images.s3.us-east-2.amazonaws.com/moonrock-logo.png"
                alt="Project MoonRock"
                className="home__moonrock-logo"
              />
            </div>
          </div>
          <ReactPlayer
            className="home__vimeo"
            url="https://vimeo.com/749014952"
          />
          <div className="home__demo">Demo (Press Play)</div>
          <div className="home__list">
            <div className="home__list--row--game">
              <div className="home__list--icon">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div>
                Gain "Knowledge" by answering Math &amp; English Questions
              </div>
            </div>
          </div>
        </div>
        <div className="home__card home__readers">
          <div className="home__card--label">Kids' Books (Upsell)</div>
          <div className="home__logo home__logo-nfr">
            <span className="home__logo--upper">NO FUSS</span>
            <span className="home__logo--lower">READERS</span>
          </div>
          <div className="home__card--middle-nfr">
            <img
              src="https://nft-image-pool.s3.us-east-2.amazonaws.com/no-fuss-education/Books.png"
              alt="Reviews"
              className="home__books"
            />
          </div>
          <div className="home__list">
            <div className="home__list--row">
              <div className="home__list--icon">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div>$200 USD Net MRR</div>
            </div>

            <div className="home__list--row">
              <div className="home__list--icon">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div>
                <a
                  className="home__link-nfr"
                  href="https://nofussreaders.com/"
                  target="_blank"
                >
                  www.nofussreaders.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home__cockroach">
        <div className="home__cockroach--img"></div>
        <div className="home__cockroach--desc">
          We are product builders. <br></br>
          <br></br>
          We are "Default Alive".
          <br></br>
          <br></br>
          We will relentlessly build and itterate our product until we achieve
          product-market-fit.
        </div>
      </div>
      <div className="home__note">
        <div className="home__note--desc">
          This site was made with love for our YC Application.
          <br></br>
          <br></br>
        </div>

        <div className="home__link-nfr">
          - By Matt (Founder at No Fuss Education)
        </div>
      </div>

      <div className="home__footer">
        <div>
          {" "}
          <a
            className="home__footer--link"
            href="https://www.ycombinator.com/"
            target="_blank"
          >
            Back to Y Combinator{" "}
          </a>{" "}
        </div>
        <div className="home__footer--icon">
          <i className="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default Home;
